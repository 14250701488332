/* General Styles */
body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #e6eaf3;
  color: #333;
  overflow-x: hidden;
}

h1, h2 {
  margin: 0;
  font-weight: 700;
  color: #2a2d44;
  animation: fadeInUp 1s ease-out;
}

p {
  margin: 0;
  line-height: 1.6;
  animation: fadeInUp 1.2s ease-out;
  color: #555;
}

button {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  padding: 12px 24px;
  border-radius: 25px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  animation: fadeInUp 1.4s ease-out;
}

/* Animations */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

button:hover {
  transform: translateY(-3px);
}

/* Hero Section */
.hero {
  background: linear-gradient(135deg, #d4d8f0, #e6eaf3);  /* Lightened gradient background */
  color: #2a2d44;
  padding: 100px 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: fadeInUp 0.8s ease-out;
}

.hero h1 {
  font-size: 3rem;
  margin-bottom: 20px;
}

.hero p {
  font-size: 1.25rem;
  margin-bottom: 40px;
}

.hero button {
  background-color: #716b94;
  color: #f5f5f7;
  font-weight: bold;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
}

.hero button:hover {
  background-color: #5a5781;
}

/* Features Section */
.features {
  padding: 80px 20px;
  text-align: center;
  background-color: #f9fafc;  /* Very light background for contrast */
  animation: fadeInUp 1s ease-out;
}

.features h2 {
  font-size: 2.5rem;
  margin-bottom: 40px;
  color: #2a2d44;
}

.features ul {
  list-style: none;
  padding: 0;
}

.features li {
  font-size: 1.25rem;
  margin-bottom: 20px;
  color: #555;
}

/* About Section */
.about {
  padding: 80px 20px;
  text-align: center;
  background-color: #e6eaf3;  /* Light background consistent with overall theme */
  animation: fadeInUp 1.2s ease-out;
}

.about h2 {
  font-size: 2.5rem;
  margin-bottom: 40px;
  color: #2a2d44;
}

.about p {
  font-size: 1.1rem;
  max-width: 700px;
  margin: 0 auto;
  color: #555;
}

/* Signup Form Section */
.signup {
  padding: 80px 20px;
  text-align: center;
  background-color: #f9fafc;  /* Matches features section for consistency */
  animation: fadeInUp 1.4s ease-out;
}

.signup h2 {
  font-size: 2.5rem;
  margin-bottom: 40px;
  color: #2a2d44;
}

/* Aligning input, button, and error message */
.signup form {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center align input and button */
  max-width: 400px; /* Restrict the form width for better alignment */
  margin: 0 auto; /* Center the form on the page */
}

.signup input {
  padding: 12px;
  font-size: 16px;
  border-radius: 25px;
  border: 2px solid #ddd;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 5px;
  animation: fadeInUp 1.6s ease-out;
}

/* Styling for the custom validation message */
.error-message {
  color: #d9534f; /* Danger color (red) */
  font-size: 0.875rem;
  margin-top: 5px;
  width: 100%;
  margin-bottom: 10px;
}

/* Style for input field when there is an error */
.input-error {
  border: 2px solid #d9534f;
  background-color: #f8d7da; /* Light red background */
  color: #6c757d; /* Text color adjusted for readability */
}

/* Adjust button spacing */
.signup button {
  margin-top: 10px;
  padding: 12px 24px;
  font-size: 16px;
  border-radius: 25px;
  background-color: #716b94;
  color: #f5f5f7;
  font-weight: bold;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  border: none;
  cursor: pointer;
  display: inline-block;
}

.signup button:hover {
  background-color: #5a5781;
}

/* Footer */
footer {
  background-color: #2a2d44;
  color: white;
  padding: 40px 20px;
  text-align: center;
  animation: fadeInUp 1.8s ease-out;
}

footer p {
  margin: 0;
  font-size: 0.9rem;
}

/* Responsive Design */
@media (max-width: 768px) {
  .hero h1 {
    font-size: 2.5rem;
  }

  .features h2, .about h2, .signup h2 {
    font-size: 2rem;
  }

  .signup form {
    flex-direction: column;
  }

  .signup input {
    margin-bottom: 10px;
  }

  .signup button {
    width: 100%;
  }
}

.hero .CosmoCodeIcon {
  position: absolute;
  top: 20px;
  left: 30px; /* Adjust this value for desired margin */
  margin-right: 20px; /* Adjust this value for spacing between icon and text */
}

@media only screen and (max-width: 768px) {
  .hero .CosmoCodeIcon {
    display: none;
  }

  .hero h1,
  .hero p,
  .hero button {
    margin-left: 0; /* Remove margin when the icon is hidden */
  }
}


/* Notification Styles */
.notification {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  margin: 15px auto; /* Center it within the container */
  border-radius: 5px;
  font-size: 1rem;
  position: relative;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 400px; /* Set a max-width to prevent it from being too wide */
  width: 100%; /* Make sure it respects its parent's width */
}

.notification.success {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

.notification.error {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}

.notification .close-btn {
  background: none;
  border: none;
  color: inherit;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0;
  margin: 0;
}

.input-error {
  border-color: #d9534f;
  box-shadow: 0 0 5px rgba(217, 83, 79, 0.5);
}
